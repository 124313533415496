
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import AddLayerProductionProcessModal from '../modals/addLayerProductionProcessModal.vue';
import EditLayerProductionProcessModal from '../modals/editLayerProductionProcessModal.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class viewWeastageData extends Vue {

    @Prop() allProductionProcesses: { [key: string]: OM.FabricProductionProcess[]; } = {};
    @Prop() productionPhase: string;
    @Prop() layers: OM.LayerComposition[];

    created() {}

    get processByLayerName(){

        var result = {};
        
        var keys = Object.keys(this.allProductionProcesses); 
        keys.forEach(key => {
            result[key] = [];
        });

        keys.forEach( key => {
            result[key] = this.allProductionProcesses[key]
            .filter( x => x.productionPhase.text == this.productionPhase)
            .filter( x => !x.rawMaterialIndipendent)
        })

        return result;
    }

    get isWeastagePercentageGSMVisible(){
        var keys = Object.keys(this.processByLayerName); 
        var result = [];

        keys.forEach( key => {
            result.push(...this.processByLayerName[key]);
        })

        return result
            .filter( x => x.productionPhase.text == this.productionPhase)
            .some( x => x.productionPhase.showWastage);
    }

    getTotalByLayer(property: string, layer: string){
        return this.layers.filter( x => x.layerName == layer)
            .reduce((sum, item) => {
                return sum + item.fabricCompositions.reduce( (sum, item) => {
                    return sum + item[property];
                }, 0)
            }, 0)
    }

    getTotal(property: string){
        var result = 0;

        Object.keys(this.processByLayerName).forEach(layer => {
            result += this.layers.filter( x => x.layerName == layer)
                .reduce((sum, item) => {
                    return sum + item.fabricCompositions.reduce( (sum, item) => {
                        return sum + item[property];
                    }, 0)
                }, 0)
        });

        return result;
    }
}
