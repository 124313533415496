
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { EstimationClient } from '@/services/Services';
import utils from '@/utils';

@Options({})
export default class addLayerProductionProcessModal extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() layer: OM.LayerComposition;
    @Prop() allProductionProcesses: OM.ProductionProcessListVM[];
    @Prop() currentProductionProcesses: OM.FabricProductionProcess[];
    @Prop() countries: OM.TextIdentifier[];
    @Prop() productionPhase: string;
    @Prop() saveCallback: (layer: OM.AddEstimationLayerProductionProcessVM) => void;
    @Prop() productionProcessName: string;

    model = new OM.AddEstimationLayerProductionProcessVM();
    originCountryReadonly: boolean = false;
    hideMaterialsSelection: boolean = false;

    created() {
        this.model.productionProcessIdentifier = this.allProductionProcesses.find(x => x.name == this.productionProcessName)?.identifier;
        this.countries.unshift({
            text: this.$localizationService.getLocalizedValue("Generic", "Generic"),
            identifier: ""
        });
        
        this.model.identifier = this.estimationIdentifier;
        this.model.layerName = this.layer.layerName;

        this.hideMaterialsSelection = utils.checkIfProductionPhaseIsNextFabric(this.productionPhase);
        (<any>window).sam = this.currentProductionProcesses;
    }

    get disabled(){
        return !this.model.productionProcessIdentifier;
    }

    get filteredAllProductionProcesses(){
        let usati = this.currentProductionProcesses
            .reduce((final, x) => {
                if(!final[x.name]) //inizializza
                    final[x.name] = 0;

                final[x.name]++;
                return final;
            }, {})
        let uniques = this.currentProductionProcesses
            .filter(x => x.isUnique);

        return this.allProductionProcesses
            .filter(x => !x.isUnitCalculated && (!usati[x.name] || usati[x.name] < this.layer.fabricCompositions.length)) // && !uniques.some(c => c.name == x.name))
            .filter(x => !uniques.some(c => c.uniqueKey == x.uniqueKey))
            .reduce((final, x) => {
                if(!final[x.uniqueKey])
                    final[x.uniqueKey] = [];

                final[x.uniqueKey].push({
                    identifier: x.identifier,
                    text: x.name,
                });
                return final;
            }, {})
    }

    get filteredRawMaterials(){
        var process = this.allProductionProcesses.find( x => x.identifier == this.model.productionProcessIdentifier)
        let uniques = this.currentProductionProcesses
            .filter(x => x.isUnique)
            .reduce((final, x) => {
                if(!final[x.uniqueKey])
                    final[x.uniqueKey] = [];
                
                final[x.uniqueKey].push(x.rawMaterial.identifier)

                return final;
            }, {});

        return this.layer.fabricCompositions
            .filter( x => !process.isUnique || 
                (
                    Object.keys(uniques).indexOf(process.uniqueKey) == -1 || 
                    uniques[process.uniqueKey].indexOf(x.rawMaterial.identifier) == -1
                ))
            .map( x => x.rawMaterial);
    }

    get isUnitCalculated(){
        if(!this.model.productionProcessIdentifier)
            return false;

        return this.allProductionProcesses.find( x => x.identifier == this.model.productionProcessIdentifier).isUnitCalculated;
    }

    addOrRemoveRawMaterial(identifier: string){
        var add = Object.keys(this.model.rawMaterialCountryAndUnits).indexOf(identifier) == -1;

        if(!add){
            delete this.model.rawMaterialCountryAndUnits[identifier];
            return;
        }

        EstimationClient.getCountryOfDestinationFromPreviousStep(this.estimationIdentifier, identifier, this.productionPhase, this.layer.layerName)
        .then( x => {
            var country = this.countries.find(y => y.text == x).identifier;
            this.model.rawMaterialCountryAndUnits[identifier] = {
                key: country,
                value: 0
            }
        })
    }

    async addEveryMaterial() {
        var promises = this.filteredRawMaterials.map(async (element) => {
            var materialRis = await EstimationClient.getCountryOfDestinationFromPreviousStep(this.estimationIdentifier, element.identifier, this.productionPhase, this.layer.layerName);
            var getCountryRis = {
                materialId: element.identifier,
                countryId: materialRis
            }
            return getCountryRis;
        });

        var ris = await Promise.all(promises);
        ris.forEach(element => {
            var country = this.countries.find(y => y.text == element.countryId).identifier;
            this.model.rawMaterialCountryAndUnits[element.materialId] = {
                key: country,
                value: 0
            }
        });
    }

    async save() {
        if(this.hideMaterialsSelection) {
            var ris = await this.addEveryMaterial();
            this.saveCallback(this.model);
        }
        else
            this.saveCallback(this.model);

    }
}
