
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({})
export default class EditRawMaterialProductionProcessModal extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() allProductionProcesses: OM.ProductionProcessListVM[];
    @Prop() countries: OM.TextIdentifier[];
    @Prop() modelToEdit: OM.FabricProductionProcess;
    @Prop() saveCallback: (layer: OM.EditEstimationLayerProductionProcessVM) => void;

    model = new OM.EditEstimationLayerProductionProcessVM();
    editMode: boolean = false;
    isElectricityEmissionIndipendent: boolean = false;

    created() {
        this.countries.unshift({
            text: this.$localizationService.getLocalizedValue("GENERIC", "GENERIC"),
            identifier: ""
        });
        
        this.model.identifier = this.estimationIdentifier;

        if(this.modelToEdit) {
            this.editMode = true;
            this.model.productionProcessIdentifier = this.allProductionProcesses.find(x => x.name == this.modelToEdit.name).identifier;
            this.model.units = this.modelToEdit.units;
            this.model.productionCountry = this.modelToEdit.productionCountry.identifier;
            this.isElectricityEmissionIndipendent = this.modelToEdit.isElectricityEmissionIndipendent;
        }
    }

    get disabled(){
        var productionProcess = this.allProductionProcesses.find( x => x.identifier == this.model.productionProcessIdentifier);

        return !this.model.productionProcessIdentifier || (!productionProcess.isElectricityEmissionIndipendent && !this.model.productionCountry);
    }

    get filteredAllProductionProcesses() {
        return this.allProductionProcesses
            .filter(x => x.isUnitCalculated) // && !uniques.some(c => c.name == x.name))
            .reduce((final, x) => {
                if(!final[x.uniqueKey])
                    final[x.uniqueKey] = [];

                final[x.uniqueKey].push({
                    identifier: x.identifier,
                    text: x.name,
                });
                return final;
            }, {})
    }

    get isUnitCalculated(){
        if(!this.model.productionProcessIdentifier)
            return false;

        return this.allProductionProcesses.find( x => x.identifier == this.model.productionProcessIdentifier).isUnitCalculated;
    }

    productionProcessIdentifierUpdate(identifier: string)
    {
        this.model.productionProcessIdentifier = identifier;
        
        if(!identifier)
            return;

        var productionProcess = this.allProductionProcesses.find( x => x.identifier == identifier);
        this.isElectricityEmissionIndipendent = productionProcess.isElectricityEmissionIndipendent;
    }

    save() {
        this.saveCallback(this.model);
    }
}
